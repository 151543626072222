<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>  Manage alumnus academic requests</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Alumnus academic request
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                      class="form-control"
                      v-model="search.name"
                      label="Name, email"
                      outlined
                      clearable
                      @input="search.name = $event !== null ? $event : ''"
                      v-on:keyup.enter="getAllAlumnusAcademic()"
                      dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                      :items="academic_years"
                      class="form-control"  :menu-props="{ button: true, offsetY: true }"
                      v-model="search.academic_year_id"
                      label="Academic year"
                      item-value="id"
                      item-text="year"
                      @input="search.academic_year_id = $event !== null ? $event : ''"
                      outlined  v-on:keyup.enter="getAllAlumnusAcademic()"
                      clearable
                      dense>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select outlined dense
                            v-model="search.program_id"
                            :items="programs"
                            label="Programs"
                            @input="search.program_id = $event !== null ? $event : ''"
                            item-value="id"  :menu-props="{ button: true, offsetY: true }"
                            item-text="title"
                            v-on:keyup.enter="getAllAlumnusAcademic()"
                            clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                      @click="getAllAlumnusAcademic()"
                      class="btn btn-primary w-35 btn-search "
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Full name</th>
                  <th class="px-3 wrap-column">Academic info</th>
                  <th class="px-3 wrap-column"> Requested on</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="alumnus_academic_programs.length > 0" v-for="(academic_program,index) in alumnus_academic_programs" :key="index">

                  <td class="wrap-column px-3">
                        <span class="mb-1 font-weight-medium">
                          {{academic_program.alumnus_full_name }}
                        </span>
                    <div class="text--secondary">
                      <a class="text-info mb-1" :href="'mailto:'+academic_program.alumnus_email">
                        {{academic_program.alumnus_email ? academic_program.alumnus_email : 'NA' }}
                      </a>
                    </div>
                  </td>

                  <td class="wrap-column px-3">
                        <span class="text-secondary">
                          <span class="font-weight-black">Program :</span> {{ academic_program.program_title || "N/A" }} <br/>
                        </span>
                    <span class="text-secondary" v-if="academic_program.academic_year_title">
                          <span class="font-weight-black">Academic year :</span> {{ academic_program.academic_year_title || "N/A" }} <br/>
                        </span>
                  </td>

                  <td class="px-3 wrap-column">
                    <span class="font-weight-medium">{{academic_program.requested_date}}</span>
                  </td>

                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">


                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="reviewAlumnusAcademicProgram(academic_program)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fas fa-info-circle" style="color: #00008b"></i>
                                  </span>
                              <span class="navi-text">Review alumnus</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text   tag="div" class="navi-item">
                            <a class="navi-link" @click="deleteAlumnusAcademicProgram(academic_program.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-show="alumnus_academic_programs.length==0">
                  <td class="text-center" colspan="7">
                    <strong>No data available to display.</strong>
                  </td>
                </tr>
                </tbody>

              </table>
              <b-pagination
                  v-if="alumnus_academic_programs.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAlumnusAcademic"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number :loading="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
          v-model="dialog"
          max-width="800px"  transition="dialog-bottom-transition" scrollable>
        <v-card v-if="alumnusGraduatedProgram">
          <v-card-title>
            <span class="h5 font-weight-medium">This alumnus was created on <strong>{{alumnusGraduatedProgram.requested_date}}</strong> by <strong>{{alumnusGraduatedProgram.alumnus_full_name}}</strong></span><br>

          </v-card-title>
          <v-card-text>


            <v-container>

              <v-row>
                <v-col cols="12">
                  <span v-if="alumnusGraduatedProgram.alumnus_email && alumnusGraduatedProgram.alumnus_email!=''" class="font-weight-bold subtitle-1"> Email:</span>&nbsp;
                  <span class="font-weight-medium subtitle-1">{{alumnusGraduatedProgram.alumnus_email}}</span>
                </v-col>
                <v-col cols="12">
                  <span v-if="alumnusGraduatedProgram.program_title" class="font-weight-bold subtitle-1"> Program:</span>&nbsp;
                  <span class="font-weight-medium subtitle-1">{{alumnusGraduatedProgram.program_title}}</span>
                </v-col>
                <v-col cols="12">
                  <span v-if="alumnusGraduatedProgram.academic_year_title" class="font-weight-bold subtitle-1"> Academic year:</span>&nbsp;
                  <span class="font-weight-medium subtitle-1">{{alumnusGraduatedProgram.academic_year_title}}</span>
                </v-col>
                <v-col
                    cols="12">
                  <ckeditor :config="editorConfig" v-model="alumnus_graduated_program.approved_comment">
                  </ckeditor>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                text class="cancel-btn"
                x-large
                @click="closeDialog">
              Close
            </v-btn>
            <v-btn dark color="red" x-large :loading="buttonLoadingDisapprove" @click="disapprovedAlumnusAcademic" rounded>
              <v-icon right dark small>
                fas fa-times
              </v-icon> Not approve
            </v-btn>
            <v-btn

                dark
                x-large :loading="buttonLoadingApprove"
                @click="approvedAlumnusAcademic" rounded>
              <v-icon right small dark>
                fas fa-check
              </v-icon> Approve
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import AlumnusGraduatedProgramService from "@/core/services/alumnus/AlumnusGraduatedProgramService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
const academicYearService=new AcademicYearService();
const program=new ProgramService();
const alumnusGraduatedProgram=new AlumnusGraduatedProgramService();
export default {
  data(){
    return{
      loading:false,
      dialog:false,
      page: null,
      total: 0,
      totalPage: null,
      perPage:0,
      search:{
        name:null,
        approved_status:'pending'
      },
      programs:[],
      academic_years:[],
      alumnus_academic_programs:[],
      academicYearLoading:false,
      programLoading:false,
      alumnusGraduatedProgram:null,
      alumnus_graduated_program:{
        approved_comment:null
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      buttonLoadingApprove:false,
      buttonLoadingDisapprove:false,
    }
  },
  methods:{
    getAllAlumnusAcademic(){
      alumnusGraduatedProgram
          .paginate(this.search,this.page)
          .then(response => {
            this.alumnus_academic_programs=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.totalPage = response.data.meta.last_page;
            this.perPage = parseInt(response.data.meta.per_page);
          })
          .catch(error => { })
          .finally(() => {
            this.loading=false;
          });
    },
    deleteAlumnusAcademicProgram(id){
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            alumnusGraduatedProgram
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getAllAlumnusAcademic();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },

    reviewAlumnusAcademicProgram(item){
    this.alumnusGraduatedProgram=item;
    this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
      this.alumnusGraduatedProgram=null;
      this.alumnus_graduated_program.approved_status=null;
      this.alumnus_graduated_program.approved_by=null;
      this.alumnus_graduated_program.approved_comment=null;
    },
    getAllPrograms(){
      this.programLoading=true;
      program
          .all()
          .then(response => {
            this.programs=response.data;
          })
          .catch(error => {

          })
          .finally(() => {
            this.programLoading=false;
          });
    },
    getAcademicYears() {
      this.academicYearLoading=true;
      academicYearService
          .all()
          .then(response => {
            this.academic_years = response.data;
          })
          .catch(error => {

          })
          .finally(() => {
            this.academicYearLoading=false;
          });
    },
    disapprovedAlumnusAcademic(){
      this.buttonLoadingDisapprove=true;
      this.alumnus_graduated_program.approved_status='rejected';
      this.alumnus_graduated_program.approved_by=this.currentUser.id;
      this.alumnus_graduated_program.is_rejected=1;
      alumnusGraduatedProgram
          .update(this.alumnusGraduatedProgram.id,this.alumnus_graduated_program)
          .then(response=>{
            this.$snotify.success("Alumnus academic disapproved");
            this.closeDialog();
            this.getAllAlumnusAcademic();
          })
          .catch((err) => {

          })
          .finally(() =>{
            this.buttonLoadingDisapprove = false
          });
    },
    approvedAlumnusAcademic(){
      this.buttonLoadingApprove=true;
      this.alumnus_graduated_program.approved_status='approved';
      this.alumnus_graduated_program.is_approved=1;
      this.alumnus_graduated_program.approved_by=this.currentUser.id;
      alumnusGraduatedProgram
          .update(this.alumnusGraduatedProgram.id,this.alumnus_graduated_program)
          .then(response => {
            this.$snotify.success("Alumnus academic approved");
            this.closeDialog();
            this.getAllAlumnusAcademic();
          })
          .catch(error => {

          })
          .finally(() => {
            this.buttonLoadingApprove=false;
          });
    },
  },
  mounted() {
    this.getAllPrograms();
    this.getAcademicYears();
    this.getAllAlumnusAcademic();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>
